import * as $ from "jquery";
import RecurlySubscriptionForm from "./recurly_subscription_form";
import { SubscriptionFormDirtyTrackingAllFieldsPresent } from "./subscription_form_dirty_tracking";
import { AnalyticsEvents } from "./analytics-events";

class GiftPurchaseForm extends RecurlySubscriptionForm {
    recipientEmails;
    addOnUnitAmount;
    dirtyTracking;

    constructor(form) {
        super(form);
        if (form.length === 0) {
            return;
        }

        this.recipientEmails = this.form.find("#gift_purchase_recipient_emails");
        this.addOnUnitAmount = this.form.data("add-on-unit-amount");

        this.dirtyTracking = new SubscriptionFormDirtyTrackingAllFieldsPresent(this.form);

        this.configureValidations();
        this.updateGiftPurchaseSummary();
    }

    // split list by ",", ";", and " "
    splitList(str) {
        const originalArray = $.trim(str).split(/[;, ]+/);
        return $.map(originalArray, (v) => {
            v = $.trim(v);
            if (v.length == 0) {
                return null;
            } else {
                return v;
            }
        });
    }

    addEventListeners() {
        super.addEventListeners();
        this.form.on("change", "#gift_purchase_recipient_emails", (event) => {
            if (this.form.validator.element("#gift_purchase_recipient_emails")) {
                this.updateGiftPurchaseSummary();
            }
        });

        this.form.on("ajax:success", (_event) => {
            this.trackGiftPurchase();
        });
    }

    submitForm(event) {
        return this.submitFormHandler(event);
    }

    configureValidations() {
        // validate all recipient email addresses
        $.validator.addMethod(
            "multiEmail",
            (value, element) => this.invalidEmailAddresses().length == 0,
            (params, element) => {
                const invalidEmails = this.invalidEmailAddresses();
                const emailsForDisplay = invalidEmails.map((email) => `'${escape(email)}'`).join(",");

                let errMessage = "Please enter valid email address";
                if (invalidEmails.length > 1) {
                    errMessage += "es";
                }

                errMessage += `: ${emailsForDisplay}.`;
                return errMessage;
            },
        );

        // no duplicate recipient email addresses
        $.validator.addMethod(
            "noDuplicateEmails",
            (value, element) => this.duplicatedEmailAddresses().length == 0,
            (params, element) => {
                const duplicateEmails = this.duplicatedEmailAddresses();
                const emailsForDisplay = duplicateEmails.map((email) => `'${escape(email)}'`).join(",");

                let errMessage = "Please remove duplicate email address";
                if (duplicateEmails.length > 1) {
                    errMessage += "es";
                }
                errMessage += `: ${emailsForDisplay}.`;
                return errMessage;
            },
        );

        this.form.validator = this.form.validate({
            rules: {
                "gift_purchase[recipient_emails]": {
                    required: true,
                    multiEmail: true,
                    noDuplicateEmails: true,
                },
                "gift_purchase[benefactor]": {
                    required: true,
                    minlength: 2,
                },
                "gift_purchase[email]": {
                    required: true,
                    email: true,
                },
            },
            messages: {
                "gift_purchase[benefactor]": {
                    minlength: "Please provide a name.",
                },
            },
        });

        // Monkeypatch the form's `validator::hideThese()` function so it hides the error messages on the
        // various form elements instead of setting them to empty strings, preventing us from using them
        // to notify any form errors in the future.
        this.form.validator.hideThese = function (errors) {
            errors.not(this.containers).hide();
        };
    }

    duplicatedEmailAddresses() {
        return this.parsedRecipientEmails().filter((x, i, self) => self.indexOf(x) == i && i != self.lastIndexOf(x));
    }

    invalidEmailAddresses() {
        return this.parsedRecipientEmails().filter((email) => this.isNotEmailAddress(email));
    }

    isEmailAddress(email) {
        const emailRegex = /^[^@\s]+@([^@\s]+\.)+[^@\W]+$/; // javascript version of Devise.email_regexp
        return emailRegex.test(email);
    }

    isNotEmailAddress(email) {
        return !this.isEmailAddress(email);
    }

    parsedRecipientEmails() {
        return this.splitList(this.recipientEmails.val());
    }

    recipientCount() {
        return this.validEmailAddresses().length;
    }

    updateGiftPurchaseSummary() {
        this.resetSubscriptionTax();

        const giftCount = this.recipientCount();
        const giftTotal = this.formatPrice(this.addOnUnitAmount * giftCount);

        this.form.find("[data-gift-subscription-count]").text(giftCount);
        this.form.find("[data-gift-subscription-total]").text(giftTotal);
        this.form.find("[data-subscription-total]").text(giftTotal);
    }

    validEmailAddresses() {
        return this.parsedRecipientEmails().filter((email) => this.isEmailAddress(email));
    }

    trackGiftPurchase() {
        YNABAnalytics.trackEvent(AnalyticsEvents.Subscription_GiftedSubscription);
    }
}

$(() => {
    new GiftPurchaseForm($(".gift-purchase-form"));
});
